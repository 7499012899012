import { useEffect } from "preact/hooks";
export default function Cookie() {
    useEffect(() => {
        const match = document.cookie.match(new RegExp('(^| )' + "session" + '=([^;]+)'));
        if (match) {
            let value = match[2]
        }
        else {
            const data = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            let session = ""
            for (let i = 0; i < 16; i++) {
                session += data.at((Math.floor(Math.random() * data.length)))
            }

            document.cookie = "session=" + session;
        }
    }, [])
    return <></>
}


